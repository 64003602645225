.itemContainer {
    display: flex;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.4);
    }

    100% {
        transform: scale(1);
    }
}

.itemContainer > div {
    height: clamp(3px, 0.78vw, 15px);
    width: clamp(3px, 0.78vw, 15px);
    margin: clamp(2px, 0.52vw, 10px);
    border-radius: 50%;
    animation-name: scaleAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}