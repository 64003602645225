.mainContainer {
    padding: 18px 0px 0px;
    background-color: #ebebeb;
    font-family: var(--font-primary);
}

.headingParent {
    display: flex;
    justify-content: center;
}

.heading {
    font-size: clamp(16px, 3.12vw, 60px);
    text-transform: uppercase;
    font-weight: 900;
    font-style: italic;
    letter-spacing: 2.25px;
    max-width: 65vw;
    text-align: center;
    color: #35255a;
}

.sliderContainer {
    padding: clamp(20px, 4.4vw, 85px) 0px 90px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.innerSliderContainer::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.innerSliderContainer {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.innerSliderContainer {
    display: flex;
    overflow: scroll;
    width: 100%;
    height: 100%;
}

.sliderItem {
    width: 100%;
    height: 100%;
    margin-right: 35px;
}

.imgContainer {
    width: clamp(110px, 25vw, 440px);
    height: clamp(60px, 13.5vw, 240px);
    position: relative;
}

.itemName {
    opacity: 0.7;
    font-size: clamp(10px, 1.6vw, 32px);
    font-weight: 500;
    letter-spacing: -0.51px;
    text-align: center;
    color: #333333;
    padding: 20px 0px;
}

@media screen and (max-width: 767px) {
    .heading {
        line-height: 0.75;
        letter-spacing: 1px;
    }

    .itemName {
        font-weight: 600;
        padding: 8px 0px;
        letter-spacing: -0.14px;
    }
    .sliderContainer{
        padding: 20px 0px 30px;
    }
    .sliderItem {
        margin-right: 12px;
    }
}